<template>
    
    <div class="grid">
        <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
        ></loading>
        <Toast />
        <ConfirmPopup></ConfirmPopup>
    
        <div class="col-12">
            <div class="card">
                <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />       

                <!-- <div class="grid p-fluid my-3"> -->
                    <div class="card col-12">
                        <div class="col-12 md:col-12">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-2">
                                    <span class="p-float-label">
                                        <InputText
                                            format="text"
                                            v-model="search_name"
                                            v-on:change="search(1)"
                                        >
                                        </InputText>
                                        <label>Name</label>             
                                    </span> 
                                </div>

                                <div class="field col-12 md:col-2">
                                    <span class="p-float-label">
                                        <Dropdown                                            
                                            id="status"
                                            v-model="search_slider_status"
                                            :options="slider_status_dropdown"
                                            :value="slider_status_dropdown.value"
                                            optionLabel="name"
                                            filter
                                            v-on:change="search(1)"
                                        ></Dropdown>
                                        <label for="status">Status</label>
                                    </span>
                                </div>

                                <div class="field col-6 md:col-2">
                                    <Button
                                        icon="pi pi-search"
                                        v-tooltip.bottom="'Search'"                        
                                        @click="search(1)"
                                    />
                                </div>
                               
                                <div class="field col-6 md:col-2 text-right ml-auto">
                                    <Button                  
                                        icon="pi pi-plus"
                                        v-tooltip.bottom="'Add'"
                                        @click="openNew"
                                    />  
                                </div>                          
                            </div>
                        </div>
                    </div>
                <!-- </div> -->

                <!-- <div class="card col-12"> -->
                    <div class="col-12 md:col-12">
                        <DataTable
                            :loading="loading"
                            ref="dt"
                            :lazy="true"
                            :totalRecords="totalRecords"
                            :paginator="true"
                            :value="products"
                            v-model:selection="selectedProducts"
                            :dataKey="columns[0]"
                            :rows="limit"
                            :filters="filters"
                            @page="onPage($event)"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll"
                            style="font-size: 12px"
                            :rowHover="true" showGridlines
                        >
                        <template #empty>
                        <b style="text-align:center">No records found.</b>
                        </template>
                            <template #header>
                            <div
                                class="
                                flex flex-column
                                md:flex-row md:justify-content-between md:align-items-center
                                "
                            >
                                <h5 class="m-0"><b> Manage Slider Master</b></h5>               
                                
                                <Button icon="pi pi-refresh"   v-tooltip.bottom="'Refresh'" @click="search(2)" />
                            
                            </div>
                            <div style="text-align:right;font-weight:bold"><span>Total Users:</span>{{totalRecords}}</div>	
                            </template>
                        
                            <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[14]" >
                            <template #body="{ data }"> 
                                <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
                            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
                            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
                            <span v-else>-</span>
                            </template>
                            </Column>
                
                            <Column header="Sr No" style="min-width: 5rem;text-align: center;">
                            <template #body="{ index}">
                            <span v-if="page_no==1">{{ +index+ +1 }}</span>
                                <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
                            </template>
                            </Column>
                        
                            <Column
                            header="Name"
                            style="min-width: 5rem"
                            >
                            <template #body="{ data }">
                                {{ data.name }}
                            </template>
                            </Column>
                        
                            <Column header="Image" :field="columns[10]">
                            <template #body="{ data }">
                                <img
                                style="border-radius: 50%; width: 80px; height: 80px "
                                :src="data.link"
                                :alt="'No URL found'"
                                />
                            </template>
                            </Column>
                
                            <Column header="Action" headerStyle="min-width:10rem;">
                            <template #body="slotProps">                
                                <Button
                                    icon="pi pi-pencil"
                                    title="Edit Client"
                                    v-tooltip.bottom="'Edit'"
                                    class="p-button-rounded p-button-help mr-2"
                                    @click="editProduct(slotProps.data)"
                                />
                                <Button
                                icon="pi pi-trash"
                                v-tooltip.bottom="'Delete'"
                                class="p-button-rounded p-button-warning mt-2"
                                @click="confirmDeleteProduct(slotProps.data)"
                                />
                            </template>
                            </Column>
                        </DataTable>

                    </div>
                <!-- </div> -->

                <Dialog header="Slider" v-model:visible="productDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '70vw'}" :modal="true" @hide="reset">
            
                    <div class="col-12">
                        <div class="card">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-6 md:col-6">
                                <label for="menu_name">Name</label>
                                <InputText
                                id="menu_name"
                                type="text"
                                v-model.trim="product.name"
                                required="true"
                                autofocus
                                :class="{ 'p-invalid': submitted && !product.menu_name }"
                                />
                            </div>

                            <div class="field col-6 md:col-6">
                                <label for="status">Status</label>
                                <Dropdown
                                    id="status"
                                    v-model="slider_status_selected"
                                    :options="slider_status_dropdown"
                                    :value="slider_status_dropdown.value"
                                    optionLabel="name"
                                    :class="{ 'p-invalid': submitted && !slider_status_selected }"
                                    required="true"
                                ></Dropdown>
                            </div>

                            <div class="field col-4 md:col-4">
                                <label for="menu_url">Image</label>                 
        
                                <FileUpload
                                    class="p-button-primary"
                                    mode="basic"
                                    name="model[]"
                                    :auto="true"
                                    :custom-upload="true"
                                    choose-label="Chose"
                                    accept="image/*"
                                    @uploader="handleFileUpload($event)"
                                    style="
                                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                                    border-color: #00b0b0 !important;
                                    background-color: #00b0b0 !important;
                                    "
                                />
                            </div> 
                            <div class="field col-4 md:col-4" style="padding-top: 10px;">
                                <img 
                                :src="previewImage" 
                                v-if="previewImage" 
                                style="max-width: 50%; 
                                max-height: 200px; 
                                margin-top: 10px; 
                                border: 2px solid #D3D3D3;"
                                >
                            </div> 


                            </div>
                        </div>
                    </div>
                        
                
                <template #footer>
                <Button v-if="product._id" label="Update" @click="addslider" icon="pi pi-check" class="p-button-warning"/>
                <Button v-else label="add" @click="addslider" icon="pi pi-check" class="p-button-warning"/>
                </template>
                </Dialog>

                <Dialog
                v-model:visible="deleteProductDialog"
                :style="{ width: '450px' }"
                header="Confirm"
                :modal="true"
                >
                <div class="flex align-items-center justify-content-center">
                    <i
                    class="pi pi-exclamation-triangle mr-3"
                    style="font-size: 2rem"
                    />
                    <span v-if="product"
                    >Are you sure you want to delete <b>{{ product.firsT_NAME }}</b
                    >?</span
                    >
                </div>
                <template #footer>
                    <Button
                    label="No"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="deleteProductDialog = false"
                    />
                    <Button
                    label="Yes"
                    icon="pi pi-check"
                    class="p-button-text"
                    @click="deleteslider"
                    />
                </template>
                </Dialog>
                    
            </div>
        </div>
    </div>
</template>


<script>
import { FilterMatchMode } from "primevue/api";
import JsonExcel from "vue-json-excel";
import BreadcrumbService from "../service/BreadcrumbService";
import ProductService from "../service/ProductService";

import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
        slider_status_dropdown: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      slider_status_selected: { name: "", value: "" },
      // search_slider_status: { name: "", value: "" },
      search_slider_status: null,
      attachments:"",  
      previewImage: null,     
    
      search_name:'',
      page_no:1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      user_type_search:'',
      file_attachment: "",
     
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      limit: 10,
      columns: [],
      
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
  
      BreadcrumbService : null,
      breadcrumbHome: {},
      breadcrumbItems: [],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    // this.get_client_list();
    this.user_key=localStorage.getItem("user_key");

    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.SliderMaster();
    this.breadcrumbHome=Breadcrum_data.home;
    this.breadcrumbItems=Breadcrum_data.items;
  },
  async mounted() {
    this.id = this.$route.params.id;
    if (localStorage.getItem("driver")) 
    {
      this.user_type_search={name:localStorage.getItem("driver"),code:localStorage.getItem("driver")}
      this.hide_dropdown=true;
      localStorage.driver = '';
    }
    localStorage.driver = '';
    

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    async getBrandStatusMaster() {
          var data = {
                client_id: "65a4f82f6177e69880ece5d6",
            };
            var promise = apis.BrandStatusMaster(data);
            promise.then((response) => {
                this.slider_status_dropdown = response.data.data;
          });
      },
    isNumber(evt) {  
        const charCode = evt.which ? evt.which : evt.keyCode;  
        if (  
            charCode > 31 &&  
            (charCode < 48 || charCode > 57) &&  
            charCode !== 46  
        ) {  
            evt.preventDefault();  
        }  
    },  

    async search(count)
    { 
        if (count==2) {
            this.search_name='';
            this.search_slider_status= null;
            this.previewImage="";
        }      
        await this.get_list();
        await this.get_count();
    },

    async handleFileUpload(event) {       
      var fileUp = event.files[0];
      if (fileUp) {
        this.previewImage = URL.createObjectURL(fileUp);
      }
      var file = fileUp;
      this.file = file;
      var prfeix = await this.date_time_prefix();
      var filename = prfeix + "_" + file.name;
      this.file_data = {
        filePath: 'banner/'+filename,
        contentType: file.type,
      };
      var promise = apis.upload_to_AWS(this.file_data);
      promise.then((response) => {
        axios
          .put(response.data.data, file, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": file.type,
            },
          })
          .then((response) => {
            this.attachments =
              "https://s3.ap-south-1.amazonaws.com/pcparthunt.docs/banner/" + filename;
              // "abc/pph-docs/brands" + filename;
              // this.product.url =  this.attachments;
          });
      });
    },
    async date_time_prefix() {
        const currentDateTime = new Date();
        const formattedDate = currentDateTime
            .toISOString()
            .split("T")[0]
            .replace(/-/g, "_");
        const formattedTime = currentDateTime
            .toTimeString()
            .split(" ")[0]
            .replace(/:/g, "_");
        const resultString = `${formattedDate}_${formattedTime}`;
        return resultString;
    },

    async addslider() {
      this.submitted = true;
      
      if (!this.product.name) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Slider Name",
          life: 3000,
        });
        return false;
      }
      if (!this.slider_status_selected.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Slider Status",
          life: 3000,
        });
        return false;
      }
      if (!this.attachments) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please upload Slider image",
          life: 3000,
        });
        return false;
      }
        //edit
        if (this.product._id) {
            var data = {
                "_id":this.product._id,          
                "name": this.product.name,
                "link": this.attachments,    
                "status":this.slider_status_selected.value          
        };
        console.log("Payload:",data);
        alert("edited successfully");
        this.productDialog = false;
        this.reset();
        // return false;
       
        this.isLoadingModel = true;
        var promise = apis.UpdateSlider(data);
        promise
            .then((responseapi) => {            
                this.$swal(responseapi.data.message);
                this.isLoadingModel = false;
                this.productDialog = false;
                this.get_list();
                this.get_count();
            })
            .catch((error) => {
                this.isLoadingModel = false;
                this.productDialog = false;

                this.$swal.fire(error.response.data);
                this.get_list();
                this.get_count();
            });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {          
          "name": this.product.name,
          "link": this.attachments, 
          "status":this.slider_status_selected.value       
        };
        console.log("Payload:",data1);
        alert("added successfully");
        this.productDialog = false;
        this.reset();
        // return false;
        this.isLoadingModel = true;
        var promises = apis.AddSlider(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;             
            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            this.isLoadingModel = false;
            this.productDialog = false;  
            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

     
    },
 
    reset()
    {
        this.previewImage="";
        this.slider_status_selected={ name: "", value: "" };
        this.attachments="";
    },
    
    async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
    
        if (this.totalRecords > 0) {
            this.get_list();
        }
    },
    get_count: function () {
        var status="";
        if(this.search_slider_status!=null)
        {
            status=this.search_slider_status['name'];
        }
        var data = {        
            count: true,
            status:status,
            name:this.search_name
        };
        this.loading = true;
        var promise = apis.GetSlider(data, this.id);
        promise.then((response) => {
            this.loading = false;
            this.totalRecords = response.data.data;
        });
    },
    get_list: function () {
        var status="";
        if(this.search_slider_status!=null)
        {
            status=this.search_slider_status['name'];
        }
        var data = {
            limit: this.limit,
            page_no: this.page_no,
            count: false,
            name:this.search_name,
            status:status
        };
        this.loading = true;
        var promise = apis.GetSlider(data);
        promise.then((response) => {
            this.loading = false;
            this.products = response.data.data;
            if (this.columns.length == 0) {
            if (this.products.length > 0) {
                this.columns = Object.keys(this.products[0]);
            }
            }
        });
    },
  
    openNew() {
        this.product = {};
        this.submitted = false;
        this.productDialog = true;
    },
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    },
   
   async editProduct(product) {
        this.file_attachment = "";
        this.product = { ...product };
        if(this.product.status)
        {
            this.slider_status_selected.name=this.product.status;
            this.slider_status_selected.value=this.product.status
        }
        if(this.product.link)
        {
            this.previewImage=this.product.link;
            this.attachments=this.product.link;
        }
        this.productDialog = true;
    },
    confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
    },
   
    deleteslider() {
        if (this.product._id) 
        {
          var data = {
            _id: this.product._id,
        };
        console.log(data);
        this.deleteProductDialog = false;
        // return false;

        this.isLoadingModel = true;
        var promise = apis.DeleteSlider(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Slider");
          }
        });
      }
    },
    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].id === id) {
            index = i;
            break;
            }
        }
        return index;
    },
    createId() {
        let id = "";
        var chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    },
    
    async  exportCSV() {
        this.limit=this.totalRecords;
        this.get_list();
        await this.$refs.dt.exportCSV();
    },   
    
    initFilters() {
        this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
    },
  },
  components: {downloadExcel: JsonExcel},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
